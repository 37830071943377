import React, {
  useState,
  useEffect,
  useMemo,
  lazy,
  Suspense,
  useCallback,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import AnimatedBackground from "../../components/AnimatedBackground";
import "./events.css";
import { eventsContent } from "../../constants/data";
import { Link } from "react-router-dom";
import wire from "../../assets/images/wire-7.png";

const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h2>Something went wrong. Please try refreshing the page.</h2>;
    }

    return this.props.children;
  }
}

// Skeleton Loader Components
const SkeletonCard = () => (
  <div className="skeleton-card">
    <div className="skeleton-image"></div>
    <div className="skeleton-text"></div>
    <div className="skeleton-text"></div>
  </div>
);

const SkeletonLoader = ({ count }) => (
  <div className="skeleton-loader">
    {[...Array(count)].map((_, index) => (
      <SkeletonCard key={index} />
    ))}
  </div>
);

const UpcomingEventCard = React.memo(({ item }) => (
  <div className="comingEventsCard event-card-animation" role="article">
    <div className="left">
      <div className="header">
        <Link
          to={`/events/upcoming-events/${convertToSlug(item.title)}`}
          className="event-slide-link"
        >
          <h2>{item.title}</h2>
        </Link>
        <span className="subTitle">{item.subTitle}</span>
      </div>
      <div className="comingEventsCardImg">
        <Link
          to={`/events/upcoming-events/${convertToSlug(item.title)}`}
          className="event-slide-link"
        >
          <div className="imageHolder">
            <ProgressiveImage
              lowQualitySrc={item.image.lowQualityImage}
              highQualitySrc={item.image}
              alt={item.title}
              className="eventItemImage"
            />
          </div>
        </Link>
      </div>
      <div className="eventNotice">
        <article>{item.notice}</article>
      </div>
      <div className="btnWrapper">
        <Link to="/donate" className="btnPrimary bkgRed colorWhite">
          donate
        </Link>
      </div>
    </div>
    <div className="right">
      <div className="eventOverview">
        <h3>Event Overview</h3>
        <article>{item.eventOverview}</article>
        <span>
          Date: <b>{item.date || "TBA"}</b>
        </span>
        <span>
          Time: <b>{item.time || "TBA"}</b>
        </span>
        <span>
          Location: <b>{item.location || "TBA"}</b>
        </span>
        <div className="btnWrapper">
          <Link
            to={`/events/register/${convertToSlug(item.title)}`}
            className="btnPrimary bkgYellow"
          >
            register
          </Link>
        </div>
      </div>
    </div>
  </div>
));

UpcomingEventCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    image: PropTypes.shape({
      lowQualityImage: PropTypes.string,
      highQualitySrc: PropTypes.string,
    }),
    notice: PropTypes.string,
    eventOverview: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
};

const PastEventCard = React.memo(({ item }) => (
  <div className="pastEventsCard event-card-animation" role="article">
    <div className="top">
      <Link
        to={`/events/past-events/${convertToSlug(item.title)}`}
        className="event-slide-link"
      >
        <div className="imageHolder">
          <ProgressiveImage
            lowQualitySrc={item.image.lowQualityImage}
            highQualitySrc={item.image}
            alt={item.title}
            className="eventItemImage"
          />
        </div>
      </Link>
    </div>
    <div className="bottom">
      <div className="header">
        <Link
          to={`/events/past-events/${convertToSlug(item.title)}`}
          className="event-slide-link"
        >
          <h2>{item.title}</h2>
        </Link>
        <span className="subTitle">{item.subTitle}</span>
      </div>
    </div>
  </div>
));

PastEventCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    image: PropTypes.shape({
      lowQualityImage: PropTypes.string,
      highQualitySrc: PropTypes.string,
    }),
  }).isRequired,
};

const Events = () => {
  const [upcomingPage, setUpcomingPage] = useState(1);
  const [pastPage, setPastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const eventsPerPage = 5;

  const upcomingRef = useRef(null);
  const pastRef = useRef(null);

  const currentDate = new Date();

  const isFutureDate = (dateString) => {
    if (!dateString) return false;
    const eventDate = new Date(dateString);
    return eventDate > currentDate;
  };

  const { upcomingEvents, pastEvents } = useMemo(() => {
    const upcoming = eventsContent.eventCards
      .filter((event) => isFutureDate(event.date))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    const past = eventsContent.eventCards
      .filter((event) => !isFutureDate(event.date))
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    return { upcomingEvents: upcoming, pastEvents: past };
  }, [eventsContent.eventCards]);

  const paginatedUpcomingEvents = upcomingEvents.slice(
    0,
    upcomingPage * eventsPerPage
  );
  const paginatedPastEvents = pastEvents.slice(0, pastPage * eventsPerPage);

  const handleScroll = useCallback(() => {
    if (loading) return;

    const upcomingBottom = upcomingRef.current?.getBoundingClientRect().bottom;
    const pastBottom = pastRef.current?.getBoundingClientRect().bottom;

    if (
      upcomingBottom &&
      upcomingBottom <= window.innerHeight &&
      upcomingEvents.length > paginatedUpcomingEvents.length
    ) {
      setLoading(true);
      setTimeout(() => {
        setUpcomingPage((prev) => prev + 1);
        setLoading(false);
      }, 1000);
    }

    if (
      pastBottom &&
      pastBottom <= window.innerHeight &&
      pastEvents.length > paginatedPastEvents.length
    ) {
      setLoading(true);
      setTimeout(() => {
        setPastPage((prev) => prev + 1);
        setLoading(false);
      }, 1000);
    }
  }, [
    loading,
    upcomingEvents.length,
    paginatedUpcomingEvents.length,
    pastEvents.length,
    paginatedPastEvents.length,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <ErrorBoundary>
      <section className="pageHeaderSection pageSection bkgYellow">
        <AnimatedBackground
          backgroundImage={wire}
          className="imageWire pageHeaderWire3"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)} className="colorMauve">
                    {eventsContent.heroTitle}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article className="colorMauve">
                    {eventsContent.heroContent}
                  </article>
                </motion.div>
              </motion.div>
            </div>

            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={eventsContent.heroImg.lowQualityImage}
                highQualitySrc={eventsContent.heroImg}
                alt={eventsContent.heroTitle}
                className="fadeImage eventsFaded"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className="upComingEvents"
        aria-labelledby="upcoming-events-title"
      >
        <div className="container">
          <div className="txtHeader">
            <h1 id="upcoming-events-title">Upcoming Events</h1>
          </div>
          <div className="comingEventsWrapper">
            <div className="comingEventsCards">
              {paginatedUpcomingEvents.map((item, index) => (
                <UpcomingEventCard key={index} item={item} />
              ))}
            </div>
            {loading && <SkeletonLoader count={eventsPerPage} />}
            <div ref={upcomingRef} style={{ height: 20 }} />
          </div>
        </div>
      </section>

      <section className="pastEvents" aria-labelledby="past-events-title">
        <div className="container">
          <div className="txtHeader">
            <h1 id="past-events-title">Past Events</h1>
          </div>
          <div className="pastEventsWrapper">
            <div className="pastEventsCards">
              {paginatedPastEvents.map((item, index) => (
                <PastEventCard key={index} item={item} />
              ))}
            </div>
            {loading && <SkeletonLoader count={eventsPerPage} />}
            <div ref={pastRef} style={{ height: 20 }} />
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default Events;
