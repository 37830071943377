import React, { lazy } from "react";
import "./donate.css";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import { Link } from "react-router-dom";
import wire from "../../assets/images/wire-9.png";
import wire5 from "../../assets/images/donate-story1.png";
import AnimatedBackground from "../../components/AnimatedBackground";
import { donateContent } from "../../constants/data";
import TestimonySlider from "../../components/TestimonySlider";
import StripePayment from "../../components/StripePayment";

const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

const Donate = () => {
  return (
    <>
      <section className="pageHeaderSection pageSection bkgMauve">
        <AnimatedBackground
          backgroundImage={wire}
          className="imageWire pageHeaderWire4"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1
                    variants={textVariant(1.1)}
                    className="colorYellow"
                  >
                    {donateContent.heroTitle}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article className="colorWhite">
                    {donateContent.heroContent}
                  </article>
                </motion.div>
                <motion.div variants={textVariant(1.3)} className="btnWrapper">
                  <Link
                    to={"/partner-with-us"}
                    className="btnPrimary colorMauve bkgYellow"
                  >
                    partner with us
                  </Link>
                </motion.div>
              </motion.div>
            </div>

            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={donateContent.heroImg.lowQualityImage}
                highQualitySrc={donateContent.heroImg}
                alt={donateContent.heroTitle}
                className={"fadeImage  donateFaded"}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="yourImpactSection ">
        <div className="container">
          <div className="txtContent">
            <div className="txtHeader">
              <h1 className="colorMauve">
                {donateContent.impactSection.title}
              </h1>
              <h4 className="colorMauve">
                {donateContent.impactSection.subTitle}
              </h4>
            </div>
            <div className="txt">
              <article>{donateContent.impactSection.content}</article>
            </div>
          </div>

          <div className="chooseYourImpact ">
            <div className="header">
              <h2 className="colorMauve">Choose your Impact</h2>
            </div>
            <div className="paymentWrapper ">
              <div className="holder">
                <StripePayment />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="survivorStoriesSectionDonate pageSection">
        <AnimatedBackground
          backgroundImage={wire5}
          className="imageWire survivorStoriesWireDonate"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <TestimonySlider
                header={
                  donateContent.whereDonationGoesSection.testimoniesHeader
                }
                quoteIcon={donateContent.whereDonationGoesSection.quoteIcon}
                txtColor="donate-text"
                nameColor="donate-name"
                headerColor="donate-headerColor"
                className="custom-testimony-slider"
                prevButtonClassName="donate-prev-button"
                nextButtonClassName="donate-next-button"
              />
            </div>
            <div className="ver"></div>
          </div>
        </div>
      </section>
      <section className="whereYourDonationGoes pageSection">
        <div className="container">
          <div className="txtContent">
            <div className="txtHeader">
              <h1 className="colorMauve">
                {donateContent.whereDonationGoesSection.title}
              </h1>
            </div>
            <div className="txt">
              <article>
                {donateContent.whereDonationGoesSection.content}
              </article>
            </div>
            <ProgressiveImage
              lowQualitySrc={
                donateContent.whereDonationGoesSection.image.lowQualityImage
              }
              highQualitySrc={donateContent.whereDonationGoesSection.image}
              alt={donateContent.whereDonationGoesSection.title}
              className={"chart"}
            />
            <div className="txt">
              <article>{donateContent.whereDonationGoesSection.note}</article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Donate;
