import React, { useState, useEffect, lazy, Suspense } from "react";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import { eventsContent } from "../../constants/data";
import AnimatedBackground from "../../components/AnimatedBackground";
import wire from "../../assets/images/wire-7.png";
import "./event-detail.css";

const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

const EventDetail = () => {
  const { eventSlug } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const selectedEvent = eventsContent.eventCards.find(
      (event) => convertToSlug(event.title) === eventSlug
    );
    setEvent(selectedEvent);
  }, [eventSlug]);

  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <div className="eventDetailPage">
      <section className="pageHeaderSection pageSection bkgLight">
        <AnimatedBackground
          backgroundImage={wire}
          className="imageWire pageHeaderWire3"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)} className="colorMauve">
                    {event.title}
                  </motion.h1>
                </div>
                <motion.div variants={textVariant(1.2)} className="txt">
                  <article className="colorMauve">{event.subTitle}</article>
                </motion.div>
              </motion.div>
            </div>
            <div className="ver imageFadeWrapper">
              <Suspense fallback={<div>Loading...</div>}>
                <ProgressiveImage
                  lowQualitySrc={event.image.lowQualityImage}
                  highQualitySrc={event.image}
                  alt={event.title}
                  className="fadeImage faded"
                />
              </Suspense>
            </div>
          </div>
        </div>
      </section>

      <section className="eventDetailContent">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="eventInfo">
              <p className="eventNotice">{event.notice}</p>
              <p className="eventDate">
                <strong>Date:</strong> {event.date || "TBA"}
              </p>
              <p className="eventTime">
                <strong>Time:</strong> {event.time || "TBA"}
              </p>
              <p className="eventLocation">
                <strong>Location:</strong> {event.location || "TBA"}
              </p>
            </div>

            <div className="eventOverview">
              <h2>Event Overview</h2>
              <p>{event.eventOverview}</p>
            </div>

            <div className="eventContent">
              <h2>Event Details</h2>
              <div dangerouslySetInnerHTML={{ __html: event.content }} />
            </div>

            <div className="eventActions">
              <Link
                to={`/events/register/${convertToSlug(event.title)}`}
                className="btnPrimary bkgYellow"
              >
                Register
              </Link>
              <Link to="/donate" className="btnPrimary bkgRed colorWhite">
                Donate
              </Link>
            </div>

            <Link to="/events" className="backLink">
              ← Back to Events
            </Link>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default EventDetail;
