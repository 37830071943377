import React, { lazy } from "react";
import "./testimonials.css";

import { Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import "swiper/css/pagination";
import defaultAvatar from "../../assets/images/dp.jpg";

import { testimonies, volunteerContent } from "../../constants/data";

const ProgressiveImage = lazy(() => import("../ProgressiveImage"));

const VolunteerTestimonies = () => {
  return (
    <section className="volunteerTestimonies">
      <div className="content">
        <Swiper
          className=" testimonials__container"
          modules={[Pagination]}
          spaceBetween={40}
          pagination={{ clickable: true }}
          breakpoints={{
            250: {
              slidesPerView: 1,
            },
            450: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {volunteerContent.hearOurSection.volunteerStatementCards.map(
            (item, index) => {
              return (
                <SwiperSlide key={"testifier#" + index} className="testimonial">
                  <div className="vt-card">
                    <div className="avatarWrapper">
                      <div className="avatar">
                        <ProgressiveImage
                          lowQualitySrc={
                            item.avatar?.lowQualityImage ||
                            defaultAvatar.lowQualityImage
                          }
                          highQualitySrc={item.avatar || defaultAvatar}
                          alt="beneficiary"
                          className="personInfoImage"
                        />
                      </div>
                    </div>
                    <div className="details">
                      <h3 className="name">{item.name}</h3>
                      <article>{item.content}</article>
                    </div>
                  </div>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </div>
    </section>
  );
};

export default VolunteerTestimonies;
