import React, { useState } from "react";

import {
  FaCcVisa,
  FaCcMastercard,
  FaCcApplePay,
  FaGooglePay,
  FaCcDiscover,
} from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import "./payment.css";

const DonatePayment = () => {
  return (
    <>
      <div className="paymentIcons">
        <FaCcVisa className="p-icon" />
        <FaCcMastercard className="p-icon" />
        <FaCcDiscover className="p-icon" />
        <FaCcApplePay className="p-icon" />
        <FaGooglePay className="p-icon" />
        <SiAmericanexpress className="p-icon" />
      </div>
    </>
  );
};

export default DonatePayment;
