import React from "react";
import { Helmet } from "react-helmet";
import { homeContent } from "../../constants/data";

const HomePageMetadata = () => {
  const baseUrl = "https://rescuewomeninternational.org";
  const homeUrl = baseUrl;

  const metadata = {
    title: `${homeContent.aboutSection.header} - ${homeContent.heroTitle}`,
    description: homeContent.aboutSection.textContent,
    keywords:
      "Rescue Women International, domestic abuse, women empowerment, safety, healing, support",
    og: {
      title: `${homeContent.aboutSection.header} - ${homeContent.heroContent}`,
      description: homeContent.serviceSection.textContent,
      image: `${baseUrl}/logo.png`,
      url: homeUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: `${homeContent.aboutSection.header} - ${homeContent.theSilentEpidemicSection.header}`,
      description: homeContent.theSilentEpidemicSection.textContent,
      image: `${baseUrl}/logo.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "NGO",
      name: homeContent.aboutSection.header,
      url: homeUrl,
      logo: `${baseUrl}/logo.png`,
      description: homeContent.aboutSection.textContent,
      foundingDate: "2024", // Replace with actual founding year if known
      address: {
        "@type": "PostalAddress",
        streetAddress: "123 Rescue Street", // Replace with actual address
        addressLocality: "City",
        addressRegion: "State",
        postalCode: "12345",
        addressCountry: "Country",
      },
      sameAs: [
        "https://www.facebook.com/rescuewomeninternational",
        "https://tiktok.com/rescuewomeninternational",
        "https://www.instagram.com/rescuewomeninternational",
        "https://www.youtube.com/@rescuewomeninternational",
      ],
      memberOf: {
        "@type": "Organization",
        name: "NGO", // Replace with actual affiliations if any
      },
      actionableFeedbackPolicy: `${baseUrl}/feedback-policy`, // Replace with actual URL if available
      slogan: homeContent.heroContent,
      areaServed: {
        "@type": "Country",
        name: "Ghana", // Replace with actual area served
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default HomePageMetadata;
