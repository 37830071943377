import React, { lazy } from "react";
import "./what-we-do.css";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import { Link } from "react-router-dom";
import { whatWeDoContent } from "../../constants/data";
import wire from "../../assets/images/service1.png";
import AnimatedBackground from "../../components/AnimatedBackground";
import WhatWeDoPageMetadata from "../../components/generateMetadata/whatWeDoPageMetadata";

const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

const WhatWeDo = () => {
  return (
    <>
      <WhatWeDoPageMetadata />
      <section className="pageHeaderSection pageSection bkgMauve">
        <AnimatedBackground
          backgroundImage={wire}
          className="imageWire pageHeaderWire"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1
                    variants={textVariant(1.1)}
                    className="colorYellow"
                  >
                    {whatWeDoContent.heroTitle}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article className="colorWhite">
                    {whatWeDoContent.heroContent}
                  </article>
                </motion.div>
                <motion.div variants={textVariant(1.3)} className="btnWrapper">
                  <Link
                    to={"/partner-with-us"}
                    className="btnPrimary colorMauve bkgLight"
                  >
                    partner with us
                  </Link>
                </motion.div>
              </motion.div>
            </div>

            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={whatWeDoContent.heroImg.lowQualityImage}
                highQualitySrc={whatWeDoContent.heroImg}
                alt={whatWeDoContent.heroTitle}
                className={"fadeImage"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="serviceSection">
        <div className="container">
          <div className="service-cards">
            {whatWeDoContent.serviceCards.map((item, index) => (
              <div key={index} className="service-card">
                <div className="service-card-content-wrapper">
                  <div className="card-image">
                    <img
                      src={item.img}
                      alt={item.title}
                      className="card-img-item"
                    />
                  </div>
                  <div className="card-content">
                    <h3 className="card-title">{item.title}</h3>
                    <p className="card-body">{item.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="partnerWithUsSection">
        <div className="container">
          <div className="hor up">
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorMauve">
                    {whatWeDoContent.partnerSection.title}
                  </h2>
                </div>
                <div className="txt">
                  <article>{whatWeDoContent.partnerSection.content}</article>
                </div>
                <div className="btnWrapper">
                  <Link to={"/join"} className="btnPrimary bkgMauve">
                    join us
                  </Link>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imageHolder">
                <ProgressiveImage
                  lowQualitySrc={
                    whatWeDoContent.partnerSection.image1.lowQualityImage
                  }
                  highQualitySrc={whatWeDoContent.partnerSection.image1}
                  alt={whatWeDoContent.partnerSection.title}
                  className={"eventItemImage "}
                />
              </div>
            </div>
            <div className="ver">
              <div className="imageHolder">
                <ProgressiveImage
                  lowQualitySrc={
                    whatWeDoContent.partnerSection.image2.lowQualityImage
                  }
                  highQualitySrc={whatWeDoContent.partnerSection.image2}
                  alt={whatWeDoContent.partnerSection.title}
                  className={"eventItemImage "}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatWeDo;
