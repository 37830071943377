import React from "react";

import "./slider.css";

import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { survivorStories } from "../../constants/data";

const TestimonySlider = ({
  header,
  headerColor,
  txtColor,
  nameColor,
  quoteIcon,
  prevButtonClassName,
  nextButtonClassName,
}) => {
  return (
    <>
      <div className="testimonyWrapper">
        <div className="bottom">
          <div className="quote-sign">
            <img src={quoteIcon} alt="quotation" />
          </div>
          <div className="testi-right">
            <div className="header">
              <h1 className={headerColor}>{header}</h1>
            </div>
            <Swiper
              loop={false}
              navigation={true}
              grabCursor={true}
              modules={[Navigation]}
              spaceBetween={40}
              slidesPerView={1}
              // pagination={{ clickable: true }}
              className="testimonySwiper"
            >
              {survivorStories.testimonies.map((item) => {
                return (
                  <SwiperSlide key={item.id} className="testimonial">
                    <article className="swiper-slide">
                      <p className={txtColor}>{item.text}</p>
                      <div className="testifier ">
                        <div className="testifier __details">
                          <h5 className={nameColor}>{item.person}</h5>
                        </div>
                      </div>
                    </article>
                  </SwiperSlide>
                );
              })}
              <div
                className={`swiper-button-prev ${prevButtonClassName || ""}`}
              >
                {/* <ChevronLeft size={24} /> */}
              </div>
              <div
                className={`swiper-button-next ${nextButtonClassName || ""}`}
              >
                {/* <ChevronRight size={24} /> */}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonySlider;
