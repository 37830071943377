import React, { lazy } from "react";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion";
import wire from "../../assets/images/wire-8.png";
import AnimatedBackground from "../../components/AnimatedBackground";
import "./volunteer.css";
import { volunteerContent } from "../../constants/data";
import VolunteerTestimonies from "../../components/volunteerTestimonies";
import ContactForm from "../../components/forms/contactForm";
import VolunteerPageMetadata from "../../components/generateMetadata/VolunteerPageMetadata";
import VolunteerCards from "../../components/VolunteerCard";

const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);

const Volunteer = () => {
  return (
    <>
      <VolunteerPageMetadata />
      <section className="pageHeaderSection pageSection bkgGrey">
        <AnimatedBackground
          backgroundImage={wire}
          className="imageWire pageHeaderWire2"
        />
        <div className="container">
          <div className="hor">
            <div className="ver stackTop">
              <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className="txtContent showcaseTxtContent"
              >
                <div className="txtHeader">
                  <motion.h1 variants={textVariant(1.1)} className="colorWhite">
                    {volunteerContent.heroTitle}
                  </motion.h1>
                </div>

                <motion.div variants={textVariant(1.2)} className="txt">
                  <article className="colorWhite">
                    {volunteerContent.heroContent}
                  </article>
                </motion.div>
              </motion.div>
            </div>

            <div className="ver imageFadeWrapper">
              <ProgressiveImage
                lowQualitySrc={volunteerContent.heroImg.lowQualityImage}
                highQualitySrc={volunteerContent.heroImg}
                alt={volunteerContent.heroTitle}
                className={"fadeImage "}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="helpMattersSection">
        <div className="container">
          <div className="hor">
            <div className="ver center">
              <div className="imgContentWrapper">
                <ProgressiveImage
                  lowQualitySrc={
                    volunteerContent.helpMattersSection.image.lowQualityImage
                  }
                  highQualitySrc={volunteerContent.helpMattersSection.image}
                  alt={volunteerContent.helpMattersSection.title}
                  className={"secImage"}
                />
              </div>
            </div>
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorRed">
                    {volunteerContent.helpMattersSection.title}
                  </h2>
                </div>
                <div className="txt">
                  <article>
                    {volunteerContent.helpMattersSection.content}
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="waysToVolunteer">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="txtHeader">
                <h2 className="colorRed">
                  {volunteerContent.volunteerCardsSection.title}
                </h2>
              </div>
              <div className="txt">
                <article>
                  {volunteerContent.volunteerCardsSection.content}
                </article>
              </div>
            </div>
          </div>
          <div className="bottom">
            <VolunteerCards
              cards={volunteerContent.volunteerCardsSection.volunteerCards}
            />
          </div>
        </div>
      </section>
      <section className="volunteerVoices">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="txtHeader">
                <h2 className="colorRed">
                  {volunteerContent.hearOurSection.title}
                </h2>
              </div>
              <div className="txt">
                <article>{volunteerContent.hearOurSection.content}</article>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="voices">
              <VolunteerTestimonies />
            </div>

            <div className="volunBackdrop" />
          </div>
        </div>
      </section>
      <section className="getInTouchSection">
        <div className="container">
          <div className="hor">
            <div className="ver topLeft">
              <div className="header">
                <h2 className="color">Get in touch with us to begin.</h2>
              </div>
            </div>
            <div className="ver">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Volunteer;
